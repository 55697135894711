<template>
  <v-card
    no-gutter
    style="width: 100% !important; overflow-y: scroll; max-height: 600px"
    class="pa-0"
  >
    <v-card-title class="headline mb-2"
      >{{ $t("log.logTable.title") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="logs"
      :loading="loading"
      hide-default-footer
      :search="search"
      sort-by="createdAt"
      :sort-desc="true"
      disable-pagination
    >
      <template v-slot:[`item.type`]="{ item }" v-if="logs.length > 0">
        <v-chip :color="GetTypeColor(item.type)">
          {{ GetTypeString(item.type) }}
        </v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CsharpEnum from "@/_helpers/CsharpEnum";

export default {
  name: "LogTable",

  props: {
    module: Object,
  },

  methods: {
    ...mapActions("logs", { get: "get", clearLog: "clearLog" }),

    GetTypeString(type) {
      if (CsharpEnum.LogType[type] !== undefined)
        return CsharpEnum.LogType[type].label;

      return "N/A";
    },

    GetTypeColor(type) {
      if (CsharpEnum.LogType[type] !== undefined)
        return CsharpEnum.LogType[type].color;

      return "N/A";
    },

    async updateLog() {
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }

      let query = {};
      if (this.lastId !== null) {
        query.lastId = this.lastId;
      } else {
        query.limit = 200;
      }

      await this.get(query);

      if (this.logs[0] !== undefined) {
        this.lastId = this.logs[0].logId;
      }

      var self = this;
      if (!this.noTimeout) {
        this.timer = setTimeout(function () {
          self.updateLog();
        }, 30000);
      }
    },
  },

  computed: {
    ...mapState("logs", ["logs"]),
  },

  data() {
    return {
      headers: [
        { text: this.$t("log.fields.type"), value: "type" },
        { text: this.$t("log.fields.user"), value: "user.email" },
        { text: this.$t("log.fields.message"), value: "message" },
        { text: this.$t("log.fields.createdAt"), value: "createdAt" },
      ],
      loading: true,
      search: null,
      lastId: null,
      timer: null,
      noTimeout: false,
    };
  },

  async mounted() {
    await this.clearLog();
    // Get the logs the first time and watch for updates
    await this.updateLog();

    this.loading = false;
  },

  beforeDestroy() {
    this.noTimeout = true;
    clearTimeout(this.timer);
  },

  watch: {
    logs(val) {
      if (val.length > 0) {
        this.items.unshift(val);
      } else {
        this.items = val;
      }
    },
  },
};
</script>

<style scoped>
</style>
